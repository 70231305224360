import React from 'react';
import { NavLink } from 'react-router-dom';

const CollectionThreeGrid = () => {
    const imageUrls = [
        "https://production-cdn2.patternbank.com/uploads/media/sources/000/007/662/1724945790/original/Florals-29-08_24-1.jpg?1724945790",
        "https://production-cdn.patternbank.com/uploads/media/sources/000/007/663/1724945802/original/Abstract-29-08_24-1.jpg?1724945802",
        "https://production-cdn.patternbank.com/uploads/media/sources/000/007/664/1724945819/original/Ethnic-29-08_24-5.jpg?1724945819",
        "https://production-cdn1.patternbank.com/uploads/media/sources/000/007/665/1724945830/original/Geometrics-29-08_24-1.jpg?1724945830",
        "https://production-cdn.patternbank.com/uploads/media/sources/000/007/668/1724945880/original/Animals-29-08_24-1.jpg?1724945880",
        "https://production-cdn1.patternbank.com/uploads/media/sources/000/007/667/1724945867/original/Tropical-29-08_24-3.jpg?1724945867",
    ];

    return (
        <div className="mx-auto px-4 md:px-8 lg:px-12 py-8 mb-[7%]">
            {/* Image Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                {imageUrls.map((url, index) => (
                    <NavLink
                        to='/studio'
                        key={index}
                        className={`relative flex flex-col justify-center items-center w-full h-[360px] sm:h-[250px] md:h-[28rem] overflow-hidden bg-cover bg-center rounded-lg group cursor-pointer ${
                            // Apply transition classes only on md and larger screens
                            index >= 0 ? 'md:transition-transform md:duration-300 md:transform md:hover:scale-105' : ''
                        }`}
                        style={{ backgroundImage: `url(${url})` }}
                    >
                        {/* Dark Overlay */}
                        <div className="absolute inset-0 bg-black opacity-35"></div>
                        <h3 className="relative text-white text-lg sm:text-xl md:text-2xl font-bold z-10">Image {index + 1}</h3>
                    </NavLink>
                ))}
            </div>
            <div className="flex justify-end mt-8">
                <NavLink
                    to="/studio"
                    className="text-[14px] font-medium"
                    style={{
                        padding: '0 0 0.357em',
                        color: 'inherit',
                        textDecoration: 'none',
                        background: 'linear-gradient(#bc0032, #bc0032)',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'bottom center',
                        backgroundSize: '100% 2px',
                    }}
                >
                    Shop All Categories
                </NavLink>
            </div>
        </div>
    );
};

export default CollectionThreeGrid;
