import React from 'react';

const TextSection = ({ text }) => {
  return (
    <section className='pt-0 pb-0'>
      <div>
        <section className="text-black py-12 w-full">
          <div className="px-[18%]">
            <div className="text-center">
              <p className="font-[400] font-tobias leading-9 text-xl sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">
                {text}
              </p>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default TextSection;
