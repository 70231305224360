import React from 'react';

const CatwalkSection = () => {
  return (
    <section className="py-0 bg-transparent text-inherit">
      <div>
        <section className="relative flex justify-center items-center h-auto xl:h-auto lg:h-auto md:h-[400px] sm:h-[400px]">
          {/* Image Container */}
          <div className="relative w-full">
            <div className="aspect-w-16 aspect-h-16">
              <img
                className="w-full h-[400px] xl:h-auto lg:h-auto md:h-[400px] sm:h-[400px] object-cover"
                src="https://production-cdn1.patternbank.com/uploads/media/sources/000/007/942/1729087119/original/SS25-Catwalk-Report_Header-1.jpg?1729087119"
                srcSet="
                  https://production-cdn1.patternbank.com/uploads/media/sources/000/007/942/1729087119/90/SS25-Catwalk-Report_Header-1.jpg?1729087119 90w,
                  https://production-cdn1.patternbank.com/uploads/media/sources/000/007/942/1729087119/175/SS25-Catwalk-Report_Header-1.jpg?1729087119 175w,
                  https://production-cdn1.patternbank.com/uploads/media/sources/000/007/942/1729087119/350/SS25-Catwalk-Report_Header-1.jpg?1729087119 350w,
                  https://production-cdn1.patternbank.com/uploads/media/sources/000/007/942/1729087119/525/SS25-Catwalk-Report_Header-1.jpg?1729087119 525w,
                  https://production-cdn1.patternbank.com/uploads/media/sources/000/007/942/1729087119/700/SS25-Catwalk-Report_Header-1.jpg?1729087119 700w,
                  https://production-cdn1.patternbank.com/uploads/media/sources/000/007/942/1729087119/1400/SS25-Catwalk-Report_Header-1.jpg?1729087119 1400w,
                  https://production-cdn1.patternbank.com/uploads/media/sources/000/007/942/1729087119/2800/SS25-Catwalk-Report_Header-1.jpg?1729087119 1980w
                "
                alt="Spring/Summer 2025 Catwalk Report"
                loading="lazy"
              />
            </div>
            {/* Overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-30"></div>
          </div>
          {/* Text Content Overlay */}
          <div className="absolute inset-0 flex justify-center items-center">
            <div className="text-center text-white p-6">
              <h3 className="text-3xl md:text-5xl font-bold">Spring/Summer 2025</h3>
              <p className="text-lg md:text-xl mt-2">Catwalk Print & Pattern Trend Report</p>
              <button
                className="mt-4 inline-block bg-white text-black text-[12px] xl:text-[14px] lg:text-[14px] font-semibold py-2 px-4"
              >
                EXPLORE THE REPORT
              </button>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default CatwalkSection;
