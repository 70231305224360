import React from 'react';

const CategorySection = () => {
  const categories = [
    {
      title: 'Womenswear',
      imageUrl: 'https://production-cdn.patternbank.com/uploads/media/sources/000/006/686/1684253218/original/womenswear_categories.jpg?1684253218',
      link: '#',
    },
    {
      title: 'Giftware',
      imageUrl: 'https://production-cdn1.patternbank.com/uploads/media/sources/000/006/684/1684252539/original/giftware_categories.jpg?1684252539',
      link: '#',
    },
    {
      title: 'Activewear',
      imageUrl: 'https://production-cdn.patternbank.com/uploads/media/sources/000/006/683/1684250033/original/activewear_categories.jpg?1684250033',
      link: '#',
    },
    {
      title: 'Interiors',
      imageUrl: 'https://production-cdn2.patternbank.com/uploads/media/sources/000/006/682/1684248906/original/interiors_categories.jpg?1684248906',
      link: '#',
    },
  ];

  return (
    <section className="pt-8 pb-12 bg-gray-100 text-gray-900">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          {categories.map((category, index) => (
            <a
              key={index}
              href={category.link}
              className="block bg-white shadow-md rounded-lg overflow-hidden hover:shadow-xl transition-shadow"
              aria-label={category.title}
            >
              <div className="aspect-w-1 aspect-h-1">
                <img
                  src={category.imageUrl}
                  alt={category.title}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="p-4 text-center">
                <h3 className="text-lg font-medium">{category.title}</h3>
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CategorySection;
