import React from 'react';

const TrendingSection = ({ src, heading, subheading, href, buttonText }) => {
    return (
        <section className="pt-0 pb-20">
            <div>
                <section className="relative text-white bg-gray-800 h-[400px] md:h-auto">
                    {/* Image Container */}
                    <div className="relative overflow-hidden h-full">
                        <div className="relative w-full h-full" style={{ paddingTop: '37%' }}>
                            <img
                                className="absolute inset-0 w-full h-full object-cover"
                                role="presentation"
                                src={src}
                                alt={heading}
                            />
                        </div>
                    </div>

                    {/* Body Content */}
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div className="text-center">
                            <h5 className="text-white text-2xl">{heading}</h5>
                            <p className="text-white text-5xl">{subheading}</p>
                            <a
                                href={href}
                                className="inline-block text-[12px] mt-2 text-black bg-white px-8 py-2 font-bold border-solid border-2 border-gray-900/80"
                            >
                                {buttonText}
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
};

export default TrendingSection;
