import React from 'react';

export default function CategoryList({subCategories}) {
  return (
    <ul className="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900 ">
      {subCategories.map((category) => (
        <li key={category.name} className="hover:text-red-800 hover:underline">
          <a href={category.href}>{category.name}</a>
        </li>
      ))}
    </ul>
  );
}
