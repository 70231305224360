import React from "react";
import LogoImg from "../../Assets/Images/opalLogo.png";
import { NavLink } from "react-router-dom";

const Logo = ({ alt = "Logo", width = 110, height = 83 }) => (
    <NavLink to='/' className={`flex items-center px-2 text-gray-700 hover:text-gray-900`} style={{ width, height }}>
        <img src={LogoImg} alt={alt} style={{ width, height }} loading="lazy" />
    </NavLink>
);

export default Logo;
