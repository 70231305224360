import React from 'react';
import { NavLink } from 'react-router-dom';

const CollectionFiveGrid = ({ NavLinkText }) => {
    const imageUrls = [
        "https://production-cdn1.patternbank.com/uploads/uploaded_files/attachments/004/473/154/1729709247/original/387523-preview-small.jpg?1729709247",
        "https://production-cdn2.patternbank.com/uploads/uploaded_files/attachments/004/473/806/1729749451/original/387573-preview-small.jpg?1729749451",
        "https://production-cdn2.patternbank.com/uploads/uploaded_files/attachments/004/473/789/1729748265/original/387402-preview-small.jpg?1729748265",
        "https://production-cdn1.patternbank.com/uploads/uploaded_files/attachments/004/473/779/1729748047/original/387571-preview-small.jpg?1729748047",
        "https://production-cdn2.patternbank.com/uploads/uploaded_files/attachments/004/473/628/1729732865/original/387565-preview-small.jpg?1729732865",
        "https://production-cdn.patternbank.com/uploads/uploaded_files/attachments/004/473/608/1729731965/original/387564-preview-small.jpg?1729731965",
        "https://production-cdn1.patternbank.com/uploads/uploaded_files/attachments/004/473/574/1729731052/original/387563-preview-small.jpg?1729731052",
        "https://production-cdn1.patternbank.com/uploads/uploaded_files/attachments/004/473/562/1729730122/original/387562-preview-small.jpg?1729730122",
        "https://production-cdn2.patternbank.com/uploads/uploaded_files/attachments/004/473/544/1729729171/original/387558-preview-small.jpg?1729729171",
        "https://production-cdn.patternbank.com/uploads/uploaded_files/attachments/004/473/550/1729729234/original/387559-preview-small.jpg?1729729234",
        "https://production-cdn2.patternbank.com/uploads/uploaded_files/attachments/004/473/537/1729728739/original/387557-preview-small.jpg?1729728739",
        "https://production-cdn2.patternbank.com/uploads/uploaded_files/attachments/004/473/525/1729728008/original/387555-preview-small.jpg?1729728008",
        "https://production-cdn.patternbank.com/uploads/uploaded_files/attachments/004/473/531/1729728067/original/387556-preview-small.jpg?1729728067",
        "https://production-cdn1.patternbank.com/uploads/uploaded_files/attachments/004/473/519/1729727430/original/387554-preview-small.jpg?1729727430",
        "https://production-cdn1.patternbank.com/uploads/uploaded_files/attachments/004/473/513/1729726943/original/387553-preview-small.jpg?1729726943",

    ];

    return (
        <div className="mx-auto px-4 md:px-10 lg:px-20 xl:px-36 mb-8">
            {/* Image Grid */}
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                {imageUrls.map((url, index) => (
                    <NavLink
                        to='/details'
                        key={index}
                        className="relative group flex justify-center cursor-pointer"
                    >
                        <img
                            className="w-full h-auto rounded-lg object-cover"
                            src={url}
                            alt={`image-${index}`}
                            loading="lazy"
                        />
                        <div className="w-full h-1/2 absolute bottom-0 bg-white bg-opacity-75 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-start justify-start">
                            <div className="text-start w-full my-auto px-4">
                                <h2 className="text-black text-lg font-bold opacity-0 group-hover:opacity-100 hover:text-[#bc0033]">
                                    Heading
                                </h2>
                                <p className="text-black text-xs opacity-0 group-hover:opacity-100">
                                    <span>by</span> <span className="hover:text-[#bc0033]">opg-1</span>
                                </p>
                                <hr className='my-4 border-1 border-gray-400'/>
                                <div className="flex justify-end "> 
                                    <button className="hover:text-[#bc0033]">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </NavLink>
                ))}
            </div>

            {/* Footer with NavLink */}
            <div className="flex justify-end mt-8">
                <NavLink
                    to="/studio"
                    className="text-sm"
                    style={{
                        padding: '0 0 0.357em',
                        color: 'inherit',
                        textDecoration: 'none',
                        background: 'linear-gradient(#bc0032, #bc0032)',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'bottom center',
                        backgroundSize: '100% 2px',
                    }}
                >
                    {NavLinkText}
                </NavLink>
            </div>
        </div>
    );
};

export default CollectionFiveGrid;
