import { useState } from 'react';
import { FunnelIcon } from '@heroicons/react/20/solid';
import MobileFilter from './Mobile/MobileFilterDialog';
import SortMenu from './SortMenu';
import ProductGrid from './ProductGrid';



const sortOptions = [
    { name: 'Recent', href: '#', current: true },
    { name: 'Popular', href: '#', current: false },
    { name: 'A - Z', href: '#', current: false },
];
const subCategories = [
    { name: 'All', href: '#' },
    { name: 'Floral', href: '#' },
    { name: 'Ethnic', href: '#' },
    { name: 'Border', href: '#' },
    { name: 'Geometric', href: '#' },
    { name: 'Texture', href: '#' },
    { name: 'Animals', href: '#' },
    { name: 'Dots', href: '#' },
    { name: 'Tartan', href: '#' },
    { name: 'Cartoon', href: '#' },
    { name: 'Space', href: '#' },
    { name: 'DTF', href: '#' },
];
const filters = [
    {
        id: 'color',
        name: 'Color',
        options: [
            { value: 'white', label: 'White', checked: false },
            { value: 'beige', label: 'Beige', checked: false },
            { value: 'blue', label: 'Blue', checked: true },
            { value: 'brown', label: 'Brown', checked: false },
            { value: 'green', label: 'Green', checked: false },
            { value: 'purple', label: 'Purple', checked: false },
        ],
    },
    {
        id: 'category',
        name: 'Category',
        options: [
            { value: 'new-arrivals', label: 'New Arrivals', checked: false },
            { value: 'sale', label: 'Sale', checked: false },
            { value: 'travel', label: 'Travel', checked: true },
            { value: 'organization', label: 'Organization', checked: false },
            { value: 'accessories', label: 'Accessories', checked: false },
        ],
    },
    {
        id: 'size',
        name: 'Size',
        options: [
            { value: '2l', label: '2L', checked: false },
            { value: '6l', label: '6L', checked: false },
            { value: '12l', label: '12L', checked: false },
            { value: '18l', label: '18L', checked: false },
            { value: '20l', label: '20L', checked: false },
            { value: '40l', label: '40L', checked: true },
        ],
    },
];

export default function Example() {
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

    return (
        <div className="bg-white">
            <div>
                <MobileFilter
                    mobileFiltersOpen={mobileFiltersOpen}
                    setMobileFiltersOpen={setMobileFiltersOpen}
                    filters={filters}
                    subCategories={subCategories}
                />

                <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-24">
                        <h1 className="text-4xl font-bold tracking-tight text-gray-900">All Design</h1>

                        <div className="flex items-center">
                            <SortMenu sortOptions={sortOptions} />

                            <button
                                type="button"
                                onClick={() => setMobileFiltersOpen(true)}
                                className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                            >
                                <span className="sr-only">Filters</span>
                                <FunnelIcon aria-hidden="true" className="h-5 w-5" />
                            </button>
                        </div>
                    </div>

                    <section aria-labelledby="products-heading" className="pb-24 pt-6">
                        <h2 id="products-heading" className="sr-only">
                            Products
                        </h2>

                        <ProductGrid filters={filters} subCategories={subCategories}/>
                    </section>
                </main>
            </div>
        </div>
    );
}
