import React from 'react';
import Banner from '../Components/Common/HeadingSection/Banner';
import Separator from '../Components/Common/Seperator';
import TextSection from '../Components/Common/HeadingSection/HeadingHomeSections';
import CollectionFiveGrid from '../Components/Opal/Home/TextileCollectionFiveGrid';
import TrendingSection from '../Components/Opal/Home/TrendingSection';
import CollectionThreeGrid from '../Components/Opal/Home/TextileCollectionThreeGrid';
import CategorySection from '../Components/Opal/Home/CategorySection';
import FeaturedDesigners from '../Components/Opal/Home/FeaturedDesigner';
import NewsletterSignup from '../Components/Opal/Home/NewsLetterSignUp';

const HomePage = () => {
    return (
        <div className='Home overflow-x-hidden'>
            <Banner />
            <Separator />
            <TextSection 
                text="The leading destination for exclusively licensed print and pattern designs from the world's finest independent designers" 
            />
            <div className="container py-[2%] mx-auto w-[1044px]">
                <hr className="separator border-t-2 border-gray-300 my-4" />
            </div>
            <TextSection 
                text="Shop New Arrivals" 
            />
            <CollectionFiveGrid 
                NavLinkText= "Shop more New Arrival"
            />
            <TrendingSection
                src="https://production-cdn1.patternbank.com/uploads/media/sources/000/007/939/1729085915/original/leopard-love-Patternbank-AW25-header.jpg?1729085915"
                heading="TRENDING"
                subheading="Leopard Love"
                href=""
                buttonText="EXPLORE NOW"
            />
            <TextSection 
                text="Shop By Category" 
            />
            <CollectionThreeGrid />
            <TrendingSection
                src="https://production-cdn1.patternbank.com/uploads/media/sources/000/007/633/1724940357/original/Premium_Header2.3-v8.jpg?1724940357"
                heading="Our Premium Collection"
                subheading="Exclusively Yours"
                href=""
                buttonText="Shop The Collection"
            />
            <TextSection 
                text="Trending Categories" 
            />
            <CategorySection />
            <FeaturedDesigners />
            <TextSection 
                text="Shop Editors' Picks" 
            />
            <CollectionFiveGrid 
                NavLinkText= "Shop More Editors' Picks"
            />
            <NewsletterSignup />
        </div>
    );
};

export default HomePage;
