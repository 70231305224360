import React from 'react';

const HeaderImage = () => {
  return (
    <div className="relative header-image h-[20rem] mx-0 2xl:mx-32 xl:mx-32 lg:mx-32">
      <div
        className="header-image-backdrop absolute inset-0 bg-center bg-cover"
        style={{ backgroundImage: 'url(https://production-cdn2.patternbank.com/uploads/uploaded_files/attachments/004/073/663/1701187988/original/New_Header_Nov_2023.jpg?1701187988)' }}
        id="studio-header"
      ></div>
      <div className="absolute inset-0 flex justify-center items-center">
        <h1 className="text-white text-3xl md:text-4xl bg-pink-500 px-4 py-2 font-bold">Home / Design</h1>
      </div>
    </div>
  );
};

export default HeaderImage;
