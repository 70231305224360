import React from 'react';

const FeaturedDesigners = () => {
  return (
    <section className="bg-gray-900 text-white my-[7%]">
      <div className="w-full h-full"> 
        <div className="relative">
          <div className="overflow-hidden h-[400px] md:h-auto">
            <img
              className="w-full h-full object-cover"
              src="https://production-cdn1.patternbank.com/uploads/media/sources/000/006/669/1683799971/original/Featured-Desigenrs-thin-new-4.jpg?1683799971"
              alt="Featured Designers"
              sizes="(max-width: 5rem) 5rem, (max-width: 10rem) 10rem, (max-width: 21rem) 21rem, (max-width: 32rem) 32rem, (max-width: 43rem) 43rem, (max-width: 87rem) 87rem, (max-width: 175rem) 175rem, 240rem"
            />
          </div>
          <div className="absolute inset-0 flex items-center justify-end pr-[30px] lg:pr-[100px] xl:pr-[200px] bg-gray-900 bg-opacity-[0.3]">
            <div className="text-end">
              <h3 className="text-[30px] xl:text-[45px] lg:text-[40px] font-semibold text-white">Our Featured Designers</h3>
              <a
                href="https://patternbank.com/featured-designers"
                className="inline-block mt-4 px-6 py-2 text-[12px] font-bold text-gray-900 bg-white hover:bg-gray-300 rounded"
              >
                EXPLORE THESE DESIGNERS
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedDesigners;
