import React, { useState, useRef } from "react";
import styles from './Search.module.css';

const SearchWrapper = ({ toggleMegaMenu }) => {
    const [isActive] = useState(true);
    const searchInputRef = useRef(null);

    return (
        <div className={`${styles['search-wrapper']} ${isActive ? styles.active : ''}`}>
            <div className={styles['input-holder']}>
                <input
                    type="text"
                    className={styles['search-input']}
                    placeholder="Type to search"
                    ref={searchInputRef}
                />
                <button
                    className={`${styles['search-icon']} flex items-center justify-center `}
                >
                    <svg className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="#FE5F55">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </button>
            </div>
            <span className={`${styles.close} hidden xl:block lg:block md:block`} onClick={toggleMegaMenu}></span>

            <p className="text-[12px] font-bold mt-4">Recent Search</p>
        </div>
    );
};

export default SearchWrapper;
