import React from 'react';
import HeaderImage from '../Components/Common/HeadingSection/HeaderFilterImage';
import Filter from '../Components/Common/Filter/MainFilter';

const DesignPage = () => {
  return (
    <div className="Design overflow-x-hidden">
      <HeaderImage />
      <Filter />
    </div>
  );
};

export default DesignPage;