// routes/RoutesConfig.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../Pages/HomePage';
import DesignsPage from '../Pages/DesignPage';
import TrendsPage from '../Pages/TrendsPage';
import ProductDetail from '../Pages/ProductDetail';

const RoutesConfig = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/studio" element={<DesignsPage />} />
      <Route path="/trends-report" element={<TrendsPage />} />
      <Route path="/details" element={<ProductDetail />} />
    </Routes>
  );
};

export default RoutesConfig;
