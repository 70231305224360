import React from "react";
import { BrowserRouter as Router} from "react-router-dom";
import MainLayout from "./Layout/mainLayout";
import RoutesConfig from "./Routes/RoutesConfig";
import Scroller from "./Hooks/scrollToTop";
import './App.css';

function App() {
  return (
    <Router>
      <Scroller />
      <MainLayout>
        <RoutesConfig />
      </MainLayout>
    </Router>
  );
}

export default App;
