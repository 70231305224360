import React from 'react';

const NewsletterSignup = () => {
  return (
    <section className="py-4">
      <div className="max-w-screen-lg mx-auto px-4">
        <div className="text-center">
          <h2 className="text-lg font-semibold mb-4">
            Want 10% off your first full-price order? Sign up for news, trends &amp; offers
          </h2>
          <form
            className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4 w-full"
            action="/newsletter"
            method="post"
          >
            <label className="sr-only" htmlFor="email">
              Your email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="w-auto xl:w-[440px] lg:w-[440px] px-4 py-2 border-b hover:border-black focus:outline-none focus:border-black"
              placeholder="Enter your email address"
              required
            />
            <button
              type="submit"
              className="px-6 py-2 text-[14px] text-white bg-black hover:bg-black focus:outline-none"
            >
              Subscribe
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default NewsletterSignup;
