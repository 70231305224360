import React, { useState } from 'react';
import './ProductDetail.scss';

// Sample images array
const sampleImages = [
    'https://production-cdn.patternbank.com/uploads/masks/size_600/womenswear_sweatshirt.png?1680703575',
    'https://production-cdn2.patternbank.com/uploads/masks/size_600/womenswear_dress_mannequin.png?1680703580',
    'https://production-cdn1.patternbank.com/uploads/masks/size_600/interiors_small_curtain.png?1680703593',
    'https://production-cdn2.patternbank.com/uploads/masks/size_600/giftware_soap.png?1680703630',
    'https://production-cdn.patternbank.com/uploads/masks/size_600/interiors_pillow.png?1680703610',
];

const ProductDisplay = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [backgroundSize, setBackgroundSize] = useState(49);

    const handlePrev = () => {
        setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : sampleImages.length - 1));
    };

    const handleNext = () => {
        setSelectedIndex((prevIndex) => (prevIndex < sampleImages.length - 1 ? prevIndex + 1 : 0));
    };

    const handleRangeChange = (event) => {
        setBackgroundSize(event.target.value);
    };

    return (
        <div className="flex flex-col md:flex-row items-start justify-center w-full px-4 md:px-8 lg:px-0 space-x-0 md:space-x-8">
            {/* Left Section: Images and Range Input */}
            <div className="flex flex-col items-center w-full md:w-[60%] lg:w-[50%] xl:w-[37%] px-4 relative">
                {/* Main Content Section */}
                <div className="relative w-full max-w-[31rem] p-2 mb-4">
                    {/* Main Product Image with Navigation Arrows */}
                    <div className="w-full max-w-lg h-[22rem] xl:h-[30rem] lg:h-[29rem] md:h-[22rem] xl mb-4 relative">
                        <button
                            onClick={handlePrev}
                            className="absolute w-[40px] top-1/2 left-[8px] transform -translate-y-1/2 p-2 bg-white shadow hover:bg-gray-200"
                        >
                            <span className='text-[40px]'>&#8249;</span>
                        </button>
                        <img
                            src={sampleImages[selectedIndex]}
                            alt="Selected product"
                            className="w-full h-full object-cover rounded-md shadow-md"
                            style={{
                                backgroundImage: 'url("https://production-cdn1.patternbank.com/uploads/uploaded_files/attachments/004/491/566/1730834413/original/389189-preview-large.jpg?1730834413")',
                                backgroundSize: `${backgroundSize}%`,
                                backgroundRepeat: 'repeat',
                                backgroundPosition: '-79.897% -33.2842%',
                            }}
                        />
                        <button
                            onClick={handleNext}
                            className="absolute w-[40px] top-1/2 right-[8px] transform -translate-y-1/2 p-2 bg-white shadow hover:bg-gray-200"
                        >
                            <span className='text-[40px]'>&#8250;</span>
                        </button>
                        <input
                            type="range"
                            min="20"
                            max="100"
                            value={backgroundSize}
                            onChange={handleRangeChange}
                            className="absolute w-full h-[26px] top-[50%] left-[53%] transform -translate-y-1/2 rotate-[270deg]"
                        />
                    </div>
                    {/* Thumbnail Carousel */}
                    <div className="flex w-full space-x-2 overflow-x-auto">
                        {sampleImages.map((image, index) => (
                            <button
                                key={index}
                                onClick={() => setSelectedIndex(index)}
                                className={`overflow-x-auto transition ${selectedIndex === index ? 'border-blue-500' : 'border-gray-200'
                                    }`}
                            >
                                <img
                                    src={image}
                                    alt={`Thumbnail ${index + 1}`}
                                    className="w-20 h-20 md:w-24 md:h-24 object-cover border hover:border-red-700 rounded-md"
                                    style={{
                                        backgroundImage: 'url("https://production-cdn1.patternbank.com/uploads/uploaded_files/attachments/004/491/566/1730834413/original/389189-preview-large.jpg?1730834413")',
                                        backgroundSize: '50%',
                                        backgroundRepeat: 'repeat',
                                        backgroundPosition: '-79.897% -33.2842%',
                                    }}
                                />
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            {/* Right Section: Product Information */}
            <div className="w-full h-full mb-8 md:w-[40%] lg:w-[40%] xl:w-[35%] p-6 bg-white rounded-md shadow-lg border border-gray-200 flex flex-col space-y-4">
                <h2 className="h-[19px] text-2xl font-bold">Product Name</h2>
                <p className="text-[12px] text-gray-600">
                    by <span className="font-medium underline text-red-700 cursor-pointer">OPG-1</span>
                </p>
                <p className="text-lg font-bold text-gray-800">Price:</p>
                <p className="text-[12px]">11,000</p>
                <button className="bg-blue-700 p-2 text-white rounded-md hover:bg-blue-900">Add to Cart</button>
                <p className="text-md font-bold text-gray-800">Fabric Type:</p>
                <p className="text-[12px]">96% polyester, 4% Cotton</p>
                <p className="text-md font-bold text-gray-800">Categories:</p>
                <p className="text-[12px]">Floral, Nature</p>
                <p className="text-md font-bold text-gray-800">Description:</p>
                <p className="text-[12px]">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi ea ipsa unde hic porro. Et asperiores ea omnis dolor unde porro, eveniet, recusandae, facilis natus quo obcaecati debitis iste similique?
                </p>
            </div>

        </div>
    );
};

export default ProductDisplay;
