import React from 'react';
import ProductDisplay from '../Components/Opal/ProductDetails/ProductDetails';

const CatwalkPage = () => {
  return (
    <ProductDisplay />
  );
};

export default CatwalkPage;
